import gql from 'graphql-tag';

export default gql`
subscription task($taskid: String)
{
	task(id: $taskid)
	{
		id
		status
		finalDeadline
		events
		{
			__typename
			timestamp
		}
	}
}
`
