import React from 'react';
import { useSubscription } from '@apollo/react-hooks';
import { Container, Row, Col, Badge, Card } from 'react-bootstrap';

import Progress from '../UI/Progress';
import Steps    from '../UI/Steps';
import utils    from '../../utils';
import graphql  from '../../graphql';
import './Contribution.css';

const Contribution = (props) => {
	const { data, loading, error } = useSubscription(
		graphql.contribution,
		{
			variables: { contributionid: props.routing.match.params.contributionid },
			pollInterval: 1000
		}
	)

	if (loading) { return null;              }
	if (error  ) { return `Error! ${error}`; }

	const details = utils.parseContribution(data.contribution);

	return (
		<Container id='viewTask' className='m-auto'>
			<Card border={details.class} text={details.class} className="shadow">
				<Card.Header className="font-weight-bold text-capitalize p-3">
					<h5 className='d-inline m-0'>
						Contribution { details.id }
					</h5>
					<Badge pill variant={details.class} className="float-right">
						{details.descr}
					</Badge>
				</Card.Header>
				<Card.Body>
					<Row className='item-center'>
						<Col md={details.timer ? 8 : 12}>
							<Steps
								step    = { details.progress.step }
								steps   = { details.progress.steps }
								running = { true }
								error   = { true }
							/>
						</Col>
						{
							details.timer &&
							<Col md={4}>
								<Progress
									values  = { [ 100*details.timer.fraction ] }
									labels  = { [ 'Deadline' ] }
									colors  = { [ details.color ] }
									running = { details.running }
									error   = { details.error }
								/>
								<div className='text-center'>
									Remaining: { utils.formatTime(details.timer.remaining) }
								</div>
							</Col>
						}
					</Row>
				</Card.Body>
			</Card>
		</Container>
	);
}

export default Contribution;
