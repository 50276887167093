import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import TasksGrid         from '../TasksGrid/TasksGrid';
import ContributionsGrid from '../ContributionsGrid/ContributionsGrid';

const Overview = (props) => {
	return (
		<Container id='overview' className='m-auto'>
			<Row>
				<Col md={6}>
					<h1>Tasks</h1>
					<TasksGrid
					page    = {props.routing.match.params.page}
					emitter = {props.emitter}
					network = {props.network}
					config  = {props.config}
				/>
				</Col>
				<Col md={6}>
					<h1>Contributions</h1>
					<ContributionsGrid
					page    = {props.routing.match.params.page}
					emitter = {props.emitter}
					network = {props.network}
					config  = {props.config}
				/>
				</Col>
			</Row>
		</Container>
	);
}

export default Overview;
