/*
 * Expects the following attributes:
 * - id
 * - status
 * - hash
 * - task
 *   - status
 *   - contributionDeadline
 *   - revealDeadline
 */

export default function parseContribution(contribution)
{
	let details = {};

	details.id        = contribution.id;
	details.status    = contribution.status;
	details.value     = contribution.hash;
	details.consensus = contribution.task.consensus;
	details.timming   = {
		now:          Math.floor(Date.now() / 1000),
		initialize:   Math.max(...contribution.task.events.filter(ev => ev.__typename === 'TaskInitialize').map(ev => parseInt(ev.timestamp))),
		contribution: parseInt(contribution.task.contributionDeadline),
		consensus:    Math.max(...contribution.task.events.filter(ev => ev.__typename === 'TaskConsensus').map(ev => parseInt(ev.timestamp))),
		reveal:       parseInt(contribution.task.revealDeadline),
		final:        parseInt(contribution.task.finalDeadline),
	};

	if (details.timming.final < details.timming.now && ['ACTIVE', 'REVEALING'].includes(contribution.task.status))
	{
		details.class    = 'warning';
		details.color    = '#ffbb33';
		details.descr    = 'Task timeout';
		details.progress = {
			step:  2,
			steps: [ 'Commit', 'Task timeout', 'Claim' ],
		};
		return details;
	}

	switch (contribution.status + '-' +contribution.task.status)
	{
		// contributed
		case 'CONTRIBUTED-ACTIVE':
			details.class    = 'info';
			details.color    = '#33b5e5';
			details.descr    = 'Contributed';
			details.progress = {
				step:  1,
				steps: [ 'Commit', 'Consensus', 'Reveal', 'Finalize'],
			};
			break;

		// value == consensus ? revealing : likelly invalid'
		case 'CONTRIBUTED-REVEALING':
			if (details.value === details.consensus)
			{
				details.class    = 'info';
				details.color    = '#33b5e5';
				details.descr    = 'Revealing';
				details.progress = {
					step:  2,
					steps: [ 'Commit', 'Consensus', 'Reveal', 'Finalize'],
				};
				details.timer = {
					fraction:  Math.min((details.timming.now - details.timming.consensus) / (details.timming.reveal - details.timming.consensus), 1),
					remaining: Math.max(details.timming.reveal - details.timming.now, 0),
				};
			}
			else
			{
				details.class    = 'warning';
				details.color    = '#ffbb33';
				details.descr    = 'Likelly invalid';
				details.progress = {
					step:  3,
					steps: [ 'Commit', 'Consensus', 'Invalid', 'Finalize'],
				};
				details.timer = {
					fraction:  Math.min((details.timming.now - details.timming.initialize) / (details.timming.final - details.timming.initialize), 1),
					remaining: Math.max(details.timming.final - details.timming.now, 0),
				};
			}
			break;

		// invalid
		case 'CONTRIBUTED-COMPLETED':
			details.class    = 'danger';
			details.color    = '#ff4444';
			details.descr    = 'Invalid';
			details.progress = {
				step:  4,
				steps: [ 'Commit', 'Consensus', 'Invalid', 'Finalize'],
			};
			break;

		// should not happen
		case 'PROVED-ACTIVE':
			console.error("Should not happen:", contribution);
			break;

		// revealed
		case 'PROVED-REVEALING':
			details.class    = 'info';
			details.color    = '#33b5e5';
			details.descr    = 'Revealed';
			details.progress = {
				step:  3,
				steps: [ 'Commit', 'Consensus', 'Reveal', 'Finalize'],
			};
			details.timer = {
				fraction:  Math.min((details.timming.now - details.timming.initialize) / (details.timming.final - details.timming.initialize), 1),
				remaining: Math.max(details.timming.final - details.timming.now, 0),
			};
			break;

		// success
		case 'PROVED-COMPLETED':
			details.class    = 'success';
			details.color    = '#00c851';
			details.descr    = 'Completed';
			details.progress = {
				step:  4,
				steps: [ 'Commit', 'Consensus', 'Reveal', 'Finalize'],
			};
			break;

		// failed to reveal + reopen
		case 'REJECTED-ACTIVE':
			details.class    = 'warning';
			details.color    = '#ffbb33';
			details.descr    = 'Failled to reveal';
			details.progress = {
				step:  4,
				steps: [ 'Commit', 'Consensus', 'Did not reveal', 'Reopen', 'Finalize'],
			};
			details.timer = {
				fraction:  Math.min((details.timming.now - details.timming.initialize) / (details.timming.final - details.timming.initialize), 1),
				remaining: Math.max(details.timming.final - details.timming.now, 0),
			};
			break;

		// failed to reveal + reopen (consensus)
		case 'REJECTED-REVEALING':
			details.class    = 'warning';
			details.color    = '#ffbb33';
			details.descr    = 'Failled to reveal';
			details.progress = {
				step:  4,
				steps: [ 'Commit', 'Consensus', 'Did not reveal', 'Reopen', 'Finalize'],
			};
			details.timer = {
				fraction:  Math.min((details.timming.now - details.timming.initialize) / (details.timming.final - details.timming.initialize), 1),
				remaining: Math.max(details.timming.final - details.timming.now, 0),
			};
			break;

		// failed to reveal + reopen (success)
		case 'REJECTED-COMPLETED':
			details.class    = 'danger';
			details.color    = '#ff4444';
			details.descr    = 'Failled to reveal';
			details.progress = {
				step:  5,
				steps: [ 'Commit', 'Consensus', 'Did not reveal', 'Reopen', 'Finalize'],
			};
			break;

		// task failed *
		case 'CONTRIBUTED-FAILLED':
		case 'PROVED-FAILLED':
		case 'REJECTED-FAILLED':
			details.class    = 'warning';
			details.color    = '#ffbb33';
			details.descr    = 'Task failure';
			details.progress = {
				step:  2,
				steps: [ 'Commit', 'Task failed'],
			};
			break;

		default:
			console.error(`invalid task-contribution status: ${contribution.status}-${contribution.task.status}`);
	}
	return details;
}
