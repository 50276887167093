import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import utils from '../../utils';

const ContributionsGrid = (props) => {

	const details = utils.parseContribution(props.entry);

	return (
			<div className='block'>
				<Link to={ `/${props.network}/contribution/${details.id}` }>
					<OverlayTrigger
						placement='top'
						overlay={
							<Tooltip>
								Contribution { details.id.substr(0,6) }...{ details.id.substr(-4) }
								<br/>
								<strong>{ details.descr }</strong>
							</Tooltip>
						}
					>
						<div className={ `content contribution ${details.class}` }>
						</div>
					</OverlayTrigger>
				</Link>
			</div>
	);
}

export default ContributionsGrid;
