import { default as contribution  } from './contribution';
import { default as contributions } from './contributions';
import { default as deals         } from './deals';
import { default as task          } from './task';
import { default as tasks         } from './tasks';

export default {
	contribution,
	contributions,
	deals,
	task,
	tasks,
};
