import React from 'react';
import {
	MDBIcon,
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarNav,
	MDBNavItem,
	MDBNavLink,
	MDBNavbarToggler,
	MDBCollapse,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
} from 'mdbreact';

import logo from '../assets/iExec-RLC-logo.svg';
import './Navbar.css';

class Navbar extends React.Component
{
	state = {
		isOpen: false,
	};

	toggleCollapse()
	{
		this.setState({ isOpen: !this.state.isOpen });
	}

	render()
	{
		return (
			<MDBNavbar color='black' dark expand='md'>
				<MDBNavbarBrand>
					<img src={logo} alt='logo' className='navLogo'/>
					<strong className='white-text'>
						Voyager ({this.props.network})
					</strong>
				</MDBNavbarBrand>
				<MDBNavbarToggler onClick={this.toggleCollapse.bind(this)} />
				<MDBCollapse id='navbarCollapse' isOpen={this.state.isOpen} navbar>
					<MDBNavbarNav left>

						<MDBNavItem>
							<MDBNavLink link to={ `/${this.props.network}/overview` }>Overview</MDBNavLink>
						</MDBNavItem>

					</MDBNavbarNav>
					<MDBNavbarNav right>

						<MDBNavItem>
							<MDBDropdown>
								<MDBDropdownToggle nav caret style={{ width: '150px', textAlign: 'right' }}>
									<MDBIcon icon="globe" />
								</MDBDropdownToggle>
								<MDBDropdownMenu className="dropdown-default">
									{
										Object.entries(this.props.config.networks).map(([key, value]) =>
											<MDBDropdownItem
												key={key}
												href="#!"
												onClick={() => this.props.emitter.emit('switchNetwork', key) }
											>
												{key}
											</MDBDropdownItem>
										)
									}
								</MDBDropdownMenu>
							</MDBDropdown>
						</MDBNavItem>

					</MDBNavbarNav>
				</MDBCollapse>
			</MDBNavbar>
		);
	}
}

export default Navbar;
