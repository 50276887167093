import gql from 'graphql-tag';

export default gql`
subscription deals($count: Int, $from: Int)
{
	entries: schedulerNotices(first: $count, skip: $from, orderBy: id, orderDirection: desc)
	{
		deal
		{
			id
			botSize
			botFirst
			tasks
			{
				id
				status
				finalDeadline
				events
				{
					__typename
					timestamp
				}
			}
		}
	}
}
`
