import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './Overview2.css';

import Masonry from 'react-masonry-component';
import graphql from '../../graphql';
import TasksGridEntry from '../TasksGrid/TasksGridEntry';
import { Spinner } from 'react-bootstrap';
import { useSubscription } from '@apollo/react-hooks';


const DEFAULT = {
	// pageSize: 20**2,
	pageSize: 100,
}




const PlaceHolder = () => {
	return (
		<div className='block'>
			<div className='content task empty'/>
		</div>
	);
}




const Deals = (props) => {

	const settings = { ...DEFAULT, ...props };

	const [page, setPage] = React.useState(parseInt(settings.page) || 0);

	let { data, loading, error } = useSubscription(
		graphql.deals,
		{
			variables:
			{
				from:  page * settings.pageSize,
				count: settings.pageSize
			}
		}
	)

	if (loading) { data = { entries: [] } } // TODO: add a loading icon spinner
	if (error  ) { return `Error! ${error}`; }


			// options={masonryOptions} // default {}
			// disableImagesLoaded={false} // default false
			// updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
			// imagesLoadedOptions={imagesLoadedOptions} // default {}

	return (
		<Masonry className='deals'>
			{
				data.entries.map(({ deal }) =>
				<div className='deal' key={deal.id}>
					{
						deal.tasks.map((task, i) => <TasksGridEntry key={i} entry={ ({task: task}) } {...props}/>)
					}
					{
						Array(deal.botSize - deal.tasks.length).fill().map((_,i) => <PlaceHolder key={i+data.entries.length}/>)
					}
				</div>
				)
			}
		</Masonry>
	);
}








const Overview2 = (props) => {
	return (
		<Container id='overview2' className='m-auto'>
			<Deals {...props}/>
		</Container>
	);
}

export default Overview2;
