/*
 * Expects the following attributes:
 * - id
 * - status
 * - start → startTime
 * - stop
 */

const WORKFLOW = {
	'default':
	[
		'Active',
		'Revealing',
		'Completed',
	],
	'error':
	[
		'Active',
		'Error',
		'Claim',
	],
}

export default function parseTask(task)
{
	let details = {};

	details.id     = task.id;
	details.status = task.status;
	details.start  = Math.max(...task.events.filter(ev => ev.__typename === 'TaskInitialize').map(ev => parseInt(ev.timestamp)));
	details.stop   = task.finalDeadline;
	details.now    = Math.floor(Date.now() / 1000);

	switch (task.status)
	{
		case 'ACTIVE':
		case 'REVEALING':
			details.running = details.now < details.stop;
			details.error   = !details.running;
			details.class   = details.running ? 'info'    : 'warning';
			details.color   = details.running ? '#33b5e5' : '#ffbb33';
			details.descr   = details.running ? 'Pending' : 'Overtime';
			details.progress = {
				step:  task.status === 'ACTIVE' && details.running ? 1 : 2,
				steps: details.running ? WORKFLOW['default'] : WORKFLOW['error'],
			};
			details.timer = {
				fraction:  Math.min((details.now - details.start) / (details.stop - details.start), 1),
				remaining: Math.max(details.stop - details.now, 0),
			};
			break;
		case 'COMPLETED':
			details.running = false;
			details.error   = false;
			details.class   = 'success';
			details.color   = '#00c851';
			details.descr   = 'Completed';
			details.progress = {
				step:  3,
				steps: WORKFLOW['default'],
			};
			break;
		case 'FAILLED':
			details.running = false;
			details.error   = true;
			details.class   = 'danger';
			details.color   = '#ff4444';
			details.descr   = 'Failed';
			details.progress = {
				step:  3,
				steps: WORKFLOW['error'],
			};
			break;
		default:
			console.error(`invalid task status: ${task.status}`);
	}
	return details;
}
