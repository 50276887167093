import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx      from 'clsx';
import Stepper   from '@material-ui/core/Stepper';
import Step      from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import StepConnector from '@material-ui/core/StepConnector';

import ComputerIcon  from '@material-ui/icons/Computer';
import DoneIcon      from '@material-ui/icons/Done';
import DoneAllIcon   from '@material-ui/icons/DoneAll';
import ErrorIcon     from '@material-ui/icons/Error';
import BugReportIcon from '@material-ui/icons/BugReport';

import AssignmentIcon       from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon   from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon   from '@material-ui/icons/AssignmentLate';
import HowToVoteIcon        from '@material-ui/icons/HowToVote';
import BlockIcon        from '@material-ui/icons/Block';
import RestoreIcon        from '@material-ui/icons/Restore';
import TimerIcon        from '@material-ui/icons/Timer';


const icons = {
	'Active':    <ComputerIcon/>,
	'Revealing': <DoneIcon/>,
	'Completed': <DoneAllIcon/>,
	'Error':     <ErrorIcon/>,
	'Claim':     <BugReportIcon/>,

	'Commit':         <AssignmentIcon/>,
	'Consensus':      <HowToVoteIcon/>,
	'Reveal':         <AssignmentTurnedInIcon/>,
	'Finalize':       <DoneAllIcon/>,
	'Invalid':        <AssignmentLateIcon/>,
	'Did not reveal': <BlockIcon/>,
	'Reopen':         <RestoreIcon/>,
	'Task failed':    <ErrorIcon/>,
	'Task timeout':   <TimerIcon/>,
};

class Steps extends React.Component
{

	state = {

		ColorlibConnector: withStyles({
			alternativeLabel:
			{
				top: 22,
			},
			completed:
			{
				'& $line':
				{
					backgroundImage: this.props.error
						? 'linear-gradient( 95deg,#f27121 0%,#e94057 50%,#8a2387 100%)'
						: 'linear-gradient( 95deg,#65aa00 0%,#369504 50%,#006650 100%)',
				},
			},
			line:
			{
				height:          3,
				border:          0,
				backgroundColor: '#eaeaf0',
				borderRadius:    1,
			},
		})(StepConnector),

		useColorlibStepIconStyles: makeStyles({
			root: {
				backgroundColor: '#ccc',
				zIndex:          1,
				color:           '#fff',
				width:           50,
				height:          50,
				display:         'flex',
				borderRadius:    '50%',
				justifyContent:  'center',
				alignItems:      'center',
			},
			active: {
				backgroundImage: this.props.error
					? 'linear-gradient( 95deg,#f27121 0%,#e94057 50%,#8a2387 100%)'
					: 'linear-gradient( 95deg,#65aa00 0%,#369504 50%,#006650 100%)',
				boxShadow:       '0 4px 10px 0 rgba(0,0,0,.25)',
			},
			completed: {
				backgroundImage: this.props.error
					? 'linear-gradient( 95deg,#f27121 0%,#e94057 50%,#8a2387 100%)'
					: 'linear-gradient( 95deg,#65aa00 0%,#369504 50%,#006650 100%)',
			},
		}),
	}

	genIcon(params)
	{
		const classes = this.state.useColorlibStepIconStyles();
		return (
			<div
				className={clsx(classes.root, {
					[classes.active]:    params.completed,
					[classes.completed]: params.completed,
				})}
			>
				{ icons[this.props.steps[params.icon	-1]] }
			</div>
		);
	}

	render()
	{
		return (
			<Stepper activeStep={this.props.step} alternativeLabel connector={<this.state.ColorlibConnector />}>
				{
					this.props.steps.map(label => (
						<Step key={label}>
							<StepLabel StepIconComponent={this.genIcon.bind(this)}>{label}</StepLabel>
						</Step>
					))
				}
			</Stepper>
		);
	}
}

export default Steps;
