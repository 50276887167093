import gql from 'graphql-tag';

export default gql`
subscription tasks($count: Int, $from: Int)
{
	entries: taskInitializes(first: $count, skip: $from, orderBy: timestamp, orderDirection: desc)
	{
		task
		{
			id
			status
			finalDeadline
			events
			{
				__typename
				timestamp
			}
		}
	}
}
`
