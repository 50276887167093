import React from 'react';
import { useSubscription } from '@apollo/react-hooks';
import { Container } from 'react-bootstrap';

import TasksListEntry from './TasksListEntry';

import graphql from '../../graphql';
import './TasksList.css';

const TasksList = (props) => {
	const { data, loading, error } = useSubscription(
		graphql.tasks,
		{
			pollInterval: 10000
		}
	)

	if (loading) { return null;              }
	if (error  ) { return `Error! ${error}`; }

	return (
		<Container id='viewTasks' className='m-auto'>
			<h1>Latest tasks</h1>
			<div>
			{
				data.entries.map((e,i) => <TasksListEntry key={i} index={i} entry={e} {...props}/>)
			}
			</div>
		</Container>
	);
}

export default TasksList;
