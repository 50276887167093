import React from 'react';
import { Route, Redirect     } from 'react-router-dom';
import { ApolloProvider      } from '@apollo/react-hooks';
import { ApolloClient        } from 'apollo-client';
import { split               } from '@apollo/client';
import { getMainDefinition   } from '@apollo/client/utilities';
import { InMemoryCache       } from 'apollo-cache-inmemory';
import { HttpLink            } from 'apollo-link-http';
import { WebSocketLink       } from 'apollo-link-ws';

import Loading      from './Loading';
import Navbar       from './Navbar';
import Overview     from './Overview/Overview';
import Overview2    from './Overview2/Overview2';
import Contribution from './Contribution/Contribution';
import Task         from './Task/Task';
import TasksList    from './TasksList/TasksList';

class App extends React.Component
{
	state = {
		network: null,
		client:  null,
	}

	componentDidMount()
	{
		this.configure(this.props);
	}

	componentWillReceiveProps(props)
	{
		this.configure(props);
	}

	configure(props)
	{
		const network = this.props.routing.match.params.network;
		const http    = this.props.config.networks[network].http;
		const ws      = this.props.config.networks[network].ws;
		const cache   = new InMemoryCache();
		const link    = split(
			({ query }) => {
				const definition = getMainDefinition(query);
				return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
			},
			new WebSocketLink({ uri: ws, options: { reconnect: true } }),
			new HttpLink({ uri: http }),
		);
		const client  = new ApolloClient({ cache, link });
		this.setState({ client, network });
	}

	render()
	{
		return (
			<>
				{
					this.state.client
					?
						<ApolloProvider client={this.state.client}>
							<Navbar network={this.state.network} {...this.props} />
							<Route exact path={ `/${this.state.network}`                              } render={ (props) => <Redirect     to={ `/${this.state.network}/overview/` }/> } />
							<Route exact path={ `/${this.state.network}/overview/`                    } render={ (props) => <Overview     emitter={this.props.emitter} network={this.state.network} config={this.props.config} routing={props}/> } />
							// <Route exact path={ `/${this.state.network}/overview2/`                   } render={ (props) => <Overview2    emitter={this.props.emitter} network={this.state.network} config={this.props.config} routing={props}/> } />
							<Route exact path={ `/${this.state.network}/contribution/:contributionid` } render={ (props) => <Contribution emitter={this.props.emitter} network={this.state.network} config={this.props.config} routing={props}/> } />
							<Route exact path={ `/${this.state.network}/task/:taskid`                 } render={ (props) => <Task         emitter={this.props.emitter} network={this.state.network} config={this.props.config} routing={props}/> } />
							<Route exact path={ `/${this.state.network}/tasks`                        } render={ (props) => <TasksList    emitter={this.props.emitter} network={this.state.network} config={this.props.config} routing={props}/> } />
						</ApolloProvider>
					:
						<Loading/>
				}
			</>
		);
	}
}

export default App;
