import React from 'react';
import Chart   from 'react-apexcharts';

class Progress extends React.Component
{
	render()
	{
		const options = {
			labels: this.props.labels,
			colors: this.props.colors,
			plotOptions: {
				radialBar: {
					hollow: {
						size: '70%',
					},
					track: {
						margin: 0, // margin is in pixels
						dropShadow: {
							enabled: true,
							top: -3,
							left: 0,
							blur: 4,
							opacity: 0.35
						}
					},
					dataLabels: {
						name: {
							offsetY: -10,
							show: true,
							color: '#888',
							fontSize: '17px'
						},
						value: {
							formatter: (v) => `${v.toFixed(0)}%`,
							color: '#111',
							fontSize: '36px',
							show: true,
						}
					}
				}
			},
		};

		return (
			<Chart
				options = { options }
				series  = { this.props.values }
				type    = 'radialBar'
				width   = '100%'
			/>
		);
	}
}

export default Progress;
