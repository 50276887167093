import gql from 'graphql-tag';

export default gql`
subscription contribution($contributionid: String)
{
	contribution(id: $contributionid)
	{
		id
		status
		hash
		task
		{
			id
			status
			consensus
			revealDeadline
			contributionDeadline
			finalDeadline
			events
			{
				__typename
				timestamp
			}
		}
	}
}
`
