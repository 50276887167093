import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, ProgressBar } from 'react-bootstrap';

import utils from '../../utils';

const TasksListEntry = (props) => {

	const details = utils.parseTask(props.entry.task);

	return (
		<Link to={ `/${props.network}/task/${details.id}` }>
		<Card bg='light' border={ details.class } text={ details.class } className='shadow mb-3'>
			<Card.Header className='font-weight-bold text-capitalize p-2'>
				<h6 className='d-inline'>
					Task { details.id.substr(0,10) }...{ details.id.substr(-8) }
				</h6>
				<Badge pill variant={ details.class } className='float-right'>
					{ details.descr }
				</Badge>
			</Card.Header>
			{
				<Card.Body>
					<ProgressBar
						striped
						{ ...details.progress }
						variant  = {details.class}
						animated = {details.running}
						now      = {details.timer ? details.timer.fraction : 1}
						max      = {1}
					/>
				</Card.Body>
			}
		</Card>
		</Link>

	);
}

export default TasksListEntry;
